[class*="heading"] {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
}
[class*="paragraph"] {
  font-style: normal;
  font-family: var(--font-family-primary);
  line-height: 1.4;
}

.heading-primary {
  font-size: var(--size-primary);
}

.heading-secondary {
  font-size: var(--size-secondary);
}

.paragraph-one {
  font-size: var(--size-paragraph-one);
  font-weight: 400;
}

.paragraph-two {
  font-size: var(--size-paragraph-two);
  font-weight: 700;
}

.paragraph-caption {
  font-size: var(--size-paragraph-two);
  font-weight: 400;
}