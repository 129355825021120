@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin flexAllCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flexColumnAllCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin absTL {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@mixin arImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin arrow($size: 3rem,$dir: up,$bs: 2px) {
  position: relative;
  height: $size;
  width: $size;

  &::after {
    content: "";
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%,-50%) rotate(45deg);

    @if($dir == up){
      border-left: $bs solid #000;
      border-top: $bs solid #000;
    } @else if($dir == right){
      border-top: $bs solid #000;
      border-right: $bs solid #000;
    } @else if($dir == down){
      border-right: $bs solid #000;
      border-bottom: $bs solid #000;
    } @else {
      border-bottom: $bs solid #000;
      border-left: $bs solid #000;
    }
  }
}

// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 960px:    Tablet portrait
960 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 60em) {
      @content;
    } //960px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800
  }
}
