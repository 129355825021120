@use 'mixins.scss' as m;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

  @include m.respond(big-desktop) {
    font-size: 75%; //1rem = 12, 12/16
  }
}

body {
  box-sizing: border-box;
  background-color: var(--color-light-purple);
}

::selection {
  background-color: var(--color-light-purple);
  color: var(--color-dark-blue);
}
