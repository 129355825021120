@use 'mixins.scss' as m;

.article-markdown {
    [class^=heading-] {
      margin-bottom: 3rem;
  
      &.key {
        text-align: center;
      }
    }
  
    p {
      text-align: justify;
  
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }
  
    sup {
      position: relative;
    //   font-family: $font-family-tertiary;
  
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        height: 300%;
        width: 600%;
        transform: translate(-50%, -50%);
        opacity: 0;
      }
    }
  
    a {
    //   color: $color-primary;
      text-decoration: none;
    //   font-family: $font-family-primary;
    //   transition: color .2s;
  
      &:hover {
        color: #fff;
      }
    }
  
    ul,
    ol {
      margin-left: 2rem;
      margin-bottom: 3rem;
    }
  
    li {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  
    blockquote {
      width: 90%;
      margin: 0 auto;
  
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }
  
    table {
      margin: 0 auto 3rem auto;
      width: 90%;
      font-size: 1.6rem;
      table-layout: fixed;
      border-spacing: 0px;
      border-collapse: collapse;
  
      @include m.respond(phone) {
        font-size: 1.4rem;
      }
  
      th {
        text-align: left;
        // font-family: $font-family-primary;
        font-weight: 700;
      }
  
      td,
      th {
        padding: 1rem;
        // border: 1px solid $color-primary;
      }
    }
  }