.u-margin-bottom-xs { margin-bottom: 1rem; }
.u-margin-bottom-sm { margin-bottom: 2rem; }
.u-margin-bottom-md { margin-bottom: 4rem; }
.u-margin-bottom-lg { margin-bottom: 6rem; }
.u-margin-bottom-xl { margin-bottom: 8rem; }

.u-margin-top-sm { margin-top: 2rem; }
.u-margin-top-md { margin-top: 4rem; }
.u-margin-top-lg { margin-top: 6rem; }
.u-margin-top-xl { margin-top: 8rem; }

.u-center-text { text-align: center };