:root {
    --color-dark-blue: #002349;
    --color-teal: #799CA5;
    --color-medium-blue: #1C4175;
    --color-light-purple: #C6B4D4;
    --color-off-white: #FBF7F5;

    --font-family-primary: 'Montserrat', sans-serif;

    --size-primary: 4rem;
    --size-secondary: 2.4rem;
    --size-paragraph-one: 1.6rem;
    --size-paragraph-two: 1.2rem;
    --size-huge: 7.2rem;
}